import React, { useState, useRef } from "react"
import { graphql, navigate } from "gatsby"
import { Button } from "semantic-ui-react"
import LeafletMap from "../../../components/leafletmap"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import styles from "./sanctuaries.module.css"
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const Title = "Animal sanctuaries";
const DefaultPosition = [30.522331, -95.898611];
const DefaultZoom = 3;

export const query = graphql`
    query {
        allAnimalSanctuariesJson {
            nodes {
                name
                location {
                  lat
                  lon
                }
                animals
                facebook
                instagram
                twitter
                website
                youtube
            }
        }
    }
`;

const getSanctuaryDetails = nodes => {
    let result = {};
    nodes.map(n => result[n.name] = { ...n });
    return result;
}

const AnimalSanctuaries = ({ data, mobile }) => {
    let MapAspectRatio = 4 / 3;
    if (mobile) {
        MapAspectRatio = 3 / 4;
    }
    const sanctuaries = getSanctuaryDetails(data.allAnimalSanctuariesJson.nodes);
    const [selectedSanctuary, setSelectedSanctuary] = useState();

    const onPopupOpen = e => {
        e.target.closePopup();
        const selected = e.popup.options.children;
        setSelectedSanctuary(selected);
    }

    const getMarkers = nodes => {
        const result = nodes.map(n => ({
            popup: n.name,
            position: [n.location.lat, n.location.lon],
            selected: n.name === selectedSanctuary
        }));
        return result;
    }

    const markers = getMarkers(data.allAnimalSanctuariesJson.nodes);
    const mapDivRef = useRef();
    const { width } = useContainerDimensions(mapDivRef);
    const mapWidth = width;
    const mapHeight = width / MapAspectRatio;

    let map;
    if (mapWidth && mapHeight) {
        map =
            <LeafletMap
                position={DefaultPosition}
                zoom={DefaultZoom}
                markers={markers}
                onPopupOpen={onPopupOpen}
                id="mapbox/streets-v11"
            />;
    }
    const details = selectedSanctuary ? sanctuaries[selectedSanctuary] : null;

    const handleClick = (e, evt) => {
        const map = {
            linkify: details.website,
            facebook: details.facebook,
            instagram: details.instagram,
            twitter: details.twitter,
            youtube: details.youtube
        }
        return navigate(map[evt.icon]);
    };

    const getAnimals = arr => {
        switch (arr.length) {
            case 0:
                return "";
            case 1:
                return arr[0][0].toUpperCase() + arr[0].substring(1);
            default:
                return arr.reduce((a, c, i) => `${i === 1 ? a[0].toUpperCase() + a.substring(1) : a}, ${c}`);
        }
    }

    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className="citation" style={{ textAlign: "center", marginBottom: "5px", padding: 0 }}>
                        Select a marker show sanctuary details
                    </div>
                    <div id="map" />
                    <div id="info" className={`${styles.info}`} />
                    <div ref={mapDivRef} style={{
                        width: mapWidth > 0 ? mapWidth : null,
                        height: mapHeight > 0 ? mapHeight : null,
                        position: "relative"
                    }}>
                        {map}
                        <div className={`${styles.details}`} style={{ opacity: (details === null) ? 0 : 0.9 }}>
                            {
                                details === null ?
                                    (<span />) :
                                    (
                                        <>
                                            <div style={{ color: "white", textAlign: "center", backgroundColor: "#21BA45" }}>{details.name}</div>
                                            <div><span style={{ color: "#103F1F", fontSize: "0.9em" }}>Animals here:</span></div>
                                            <div><span>{getAnimals(details.animals)}</span></div>
                                            <div style={{ textAlign: "center", paddingTop: "5px" }}>
                                                <span>
                                                    <Button.Group basic>
                                                        {
                                                            details.website ? <Button icon="linkify" onClick={handleClick} /> : <></>
                                                        }
                                                        {
                                                            details.facebook ? <Button icon="facebook" onClick={handleClick} /> : <></>
                                                        }
                                                        {
                                                            details.instagram ? <Button icon="instagram" onClick={handleClick} /> : <></>
                                                        }
                                                        {
                                                            details.twitter ? <Button icon="twitter" onClick={handleClick} /> : <></>
                                                        }
                                                        {
                                                            details.youtube ? <Button icon="youtube" onClick={handleClick} /> : <></>
                                                        }

                                                    </Button.Group>
                                                </span>
                                            </div>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AnimalSanctuaries;
